import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { map, Observable, switchMap } from 'rxjs';
import { AuthService } from "./auth.service";

export interface ValidateInviteResponse {
  orgName: string;
  email: string;
  sentBy: string;
}

export type OrgId = string;

export interface ApiKey {
  id: string;
  name: string;
  createdAt: string;
  key: string;
  validEndpoints?: string[];
  validEventIds?: string[];
}

export type ApiKeySecure = Omit<ApiKey, 'key'>;

export type UserInvite = {
  id: string;
  email: string;
};

@Injectable({
  providedIn: "root",
})
export class OrganisationService {
  constructor(
    private http: HttpClient,
    private readonly authService: AuthService,
  ) { }

  createOrganisation(body: { name: string; terms: boolean; }): Observable<{ orgId: string }> {
    return this.http
      .post<{ orgId: string }>('/api/orgs', { ...body, expectedNumberAttendees: 'N/A' })
      .pipe(
        switchMap((response) =>
          this.authService
            .setCurrentOrganisation(response.orgId)
            .pipe(map(() => response))
        )
      );
  }

  updateOrganisation({ id, name }: { id: string; name: string; }): Observable<void> {
    return this.http.put(`/api/orgs/${id}`, { name })
      .pipe(
        switchMap(() =>
          this.authService.setCurrentOrganisation(id)
            .pipe(map(() => void 0))
        )
      );
  }

  listOrganisations(): Observable<{ organisations: { id: string; name: string }[] }> {
    return this.http.get<{ organisations: { id: string; name: string }[] }>('/api/orgs');
  }

  validateUserInviteCode(code: string): Observable<ValidateInviteResponse> {
    const url = `/api/organisation-invites/validate`;
    return this.http.post<ValidateInviteResponse>(url, { code });
  }

  acceptUserInviteByCode(code: string): Observable<{ orgId: string }> {
    const url = `/api/organisation-invites/accept`;
    const body = { code };
    return this.http.post<{ orgId: string }>(url, body);
  }

  removeInvite(orgId: string, inviteId: string) {
    const url = `/api/orgs/${orgId}/invites/${inviteId}`;
    return this.http.delete<any>(url);
  }

  addInvite(orgId: string, userEmail: string, sentBy: string, role: string, origin: string) {
    const url = `/api/orgs/${orgId}/invites`;
    const body = { userEmail, sentBy, origin, role };
    return this.http.post<any>(url, body);
  }

  updateUser(orgId: string, userId: string, role: string) {
    const url = `/api/orgs/${orgId}/users/${userId}`
    const body = { role };
    return this.http.put<any>(url, body);
  }

  getUserInvites(orgId: string): Observable<{ invites: UserInvite[] }> {
    const url = `/api/orgs/${orgId}/invites`;
    return this.http.get<{ invites: UserInvite[] }>(url);
  }

  deleteUser(orgId: string, userId: string) {
    const url = `/api/orgs/${orgId}/users/${userId}`;
    return this.http.delete<any>(url);
  }

  //
  // API Keys
  //

  getApiKey(orgId: string, keyId: string): Observable<ApiKey> {
    const url = `/api/orgs/${orgId}/keys/${keyId}`;
    return this.http.get<ApiKey>(url);
  }

  createApiKey(orgId: string, body: { name: string }): Observable<ApiKey> {
    const url = `/api/orgs/${orgId}/keys`;
    return this.http.post<ApiKey>(url, body);
  }

  updateApiKey(orgId: string, keyId: string, body: { name: string; validEndpoints?: string[]; validEventIds?: string[] }): Observable<ApiKey> {
    const url = `/api/orgs/${orgId}/keys/${keyId}`;
    return this.http.put<ApiKey>(url, body);
  }

  listApiKeys(orgId: string): Observable<{ keys: ApiKeySecure[] }> {
    const url = `/api/orgs/${orgId}/keys`;
    return this.http.get<{ keys: ApiKeySecure[] }>(url);
  }

  deleteApiKey(orgId: string, keyId: string): Observable<{ id: string }> {
    const url = `/api/orgs/${orgId}/keys/${keyId}`;
    return this.http.delete<{ id: string }>(url, {});
  }

}
